<template>
  <v-card outlined>
    <v-card-title>
      {{ propType }}
    </v-card-title>
    <v-card-text>
      <v-list>
        <template
          v-for="(item, index) in items"
        >
          <v-list-item :key="`${propType}_${index}`">
            <v-list-item-avatar>
              <v-icon
                class="grey lighten-1"
                dark
              >
                mdi-folder
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>

              <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-information</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index < (items.length - 1)"
            :key="`divider_${propType}_${index}`"
            style="margin-top: .5rem; margin-bottom: .5rem;"
          ></v-divider>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProfileProps',
  props: {
    propType: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default() { return []; },
    },
  }
};
</script>