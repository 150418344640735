import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import { i18n } from './plugins/i18n';
import './plugins/vee-validate';

Vue.use(VueRouter);
Vue.config.productionTip = false

new Vue({
  vuetify,
  i18n,
  render: h => h(App),
  router
}).$mount('#app')
