var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"background":"#f6f6f6"}},[_c('div',{staticStyle:{"max-width":"980px","margin":"0 auto"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('img',{staticStyle:{"width":"48px"},attrs:{"src":"https://cdn.talentics.app/profiles/dummy/icon-transparent.png"}})])],1)],1)],1)]),_c('v-divider'),_c('div',{staticStyle:{"max-width":"980px","margin":"0 auto"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('div',{staticStyle:{"height":"250px","background-color":"silver","background-image":"url('https://cdn.talentics.app/profiles/dummy/desert.jpg')","background-size":"cover","background-position":"center center"}}),_c('div',{staticStyle:{"padding-left":"1rem","margin-top":"-120px"}},[_c('v-avatar',{staticClass:"profile",staticStyle:{"border":"5px solid #ffffff"},attrs:{"color":"white","size":"150"}},[_c('v-img',{attrs:{"src":"https://cdn.talentics.app/profiles/dummy/1641805983640.jpg"}})],1)],1),_c('v-card-title',[_vm._v("Hakan Özen")]),_c('v-card-subtitle',[_vm._v("Co-founder & Technology @ Talentics")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("About")]),_c('v-card-text',[_vm._v(" As a product guy, I am; "),_c('ul',[_c('li',[_vm._v("passionate about building experiences,")]),_c('li',[_vm._v("making things happen,")]),_c('li',[_vm._v("striving for excellence,")]),_c('li',[_vm._v("an observer & a doer,")]),_c('li',[_vm._v("a team leader & a team player,")]),_c('li',[_vm._v("definitely not a scrum master. :facepalm:")])])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('ProfileProps',{attrs:{"propType":"Experience","items":[
              { title: 'Co-Founder & Technology', subtitle: 'Talentics' },
              { title: 'Founder', subtitle: 'Skeyl' },
              { title: 'Senior Frontend Developer', subtitle: 'Adphorus' },
            ]}})],1)],1),_c('v-row',[_c('v-col',[_c('ProfileProps',{attrs:{"propType":"Education","items":[
              { title: 'Marmara University', subtitle: 'Bachelor\'s degree, Econometrics' },
              { title: 'Kadıköy Anadolu Lisesi ve Maarif Koleji', subtitle: 'High School Diploma' },
            ]}})],1)],1),_c('v-row',[_c('v-col',[_c('ProfileProps',{attrs:{"propType":"Certifications","items":[
              { title: 'Fundamental Guidelines', subtitle: 'Nielsen Norman Group: UX Training, Consulting, & Research' },
            ]}})],1)],1),_c('v-row',[_c('v-col',[_c('ProfileProps',{attrs:{"propType":"Languages","items":[
              { title: 'English', subtitle: 'Full professional proficiency' },
              { title: 'Turkish', subtitle: 'Native or bilingual proficiency' },
            ]}})],1)],1)],1)],1),_c('v-divider'),_c('div',{staticStyle:{"background":"#f6f6f6"}},[_c('div',{staticStyle:{"max-width":"980px","margin":"0 auto"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('img',{staticStyle:{"max-width":"120px"},attrs:{"src":"https://cdn.talentics.app/profiles/dummy/talentics.png"}})])],1),_c('v-row',[_c('v-col',[_c('ul',{staticStyle:{"list-style-type":"none","padding":"0"}},[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("About")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Privacy")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Careers")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Help Center")])])])])],1),_c('v-row',[_c('v-col',[_vm._v(" Talentics © 2023 ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }