import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/lang/en.json';
import tr from '@/lang/tr.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'tr',
  fallbackLocale: ['en', 'tr'],
  messages: { en, tr },
});