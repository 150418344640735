<template>
  <div>
    <div style="background: #f6f6f6;">
      <div style="max-width: 980px; margin: 0 auto;">
        <v-container>
          <v-row>
            <v-col>
              <img src="https://cdn.talentics.app/profiles/dummy/icon-transparent.png" style="width: 48px;" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-divider></v-divider>
    <div style="max-width: 980px; margin: 0 auto;">
      <v-container>
        <v-row>
          <v-col>
            <v-card outlined>
              <div
                style="height: 250px; background-color: silver; background-image: url('https://cdn.talentics.app/profiles/dummy/desert.jpg'); background-size: cover; background-position: center center;"
              ></div>
              <div style="padding-left: 1rem; margin-top: -120px;">
                <v-avatar
                  class="profile"
                  color="white"
                  size="150"
                  style="border: 5px solid #ffffff;"
                >
                  <v-img src="https://cdn.talentics.app/profiles/dummy/1641805983640.jpg"></v-img>
                </v-avatar>
              </div>            
              <v-card-title>Hakan Özen</v-card-title>
              <v-card-subtitle>Co-founder &amp; Technology @ Talentics</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-title>About</v-card-title>
              <v-card-text>
                As a product guy, I am;
                <ul>
                  <li>passionate about building experiences,</li>
                  <li>making things happen,</li>
                  <li>striving for excellence,</li>
                  <li>an observer & a doer,</li>
                  <li>a team leader & a team player,</li>
                  <li>definitely not a scrum master. :facepalm:</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProfileProps
              propType="Experience"
              :items="[
                { title: 'Co-Founder & Technology', subtitle: 'Talentics' },
                { title: 'Founder', subtitle: 'Skeyl' },
                { title: 'Senior Frontend Developer', subtitle: 'Adphorus' },
              ]"
            ></ProfileProps>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProfileProps
              propType="Education"
              :items="[
                { title: 'Marmara University', subtitle: 'Bachelor\'s degree, Econometrics' },
                { title: 'Kadıköy Anadolu Lisesi ve Maarif Koleji', subtitle: 'High School Diploma' },
              ]"
            ></ProfileProps>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProfileProps
              propType="Certifications"
              :items="[
                { title: 'Fundamental Guidelines', subtitle: 'Nielsen Norman Group: UX Training, Consulting, & Research' },
              ]"
            ></ProfileProps>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProfileProps
              propType="Languages"
              :items="[
                { title: 'English', subtitle: 'Full professional proficiency' },
                { title: 'Turkish', subtitle: 'Native or bilingual proficiency' },
              ]"
            ></ProfileProps>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider></v-divider>
    <div style="background: #f6f6f6;">
      <div style="max-width: 980px; margin: 0 auto;">
        <v-container>
          <v-row>
            <v-col>
              <img src="https://cdn.talentics.app/profiles/dummy/talentics.png" style="max-width: 120px;" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ul style="list-style-type: none; padding: 0;">
                <li><a href="#">About</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Help Center</a></li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Talentics &copy; 2023
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileProps from '@/components/shared/profileProps/ProfileProps';

export default {
  name: 'Home',
  components: {
    ProfileProps,
  },
};
</script>
